<template>
    <!--三要素认证 -->
    <div class="three-auth">
        <div class="main">

            <!-- e签宝三要素认证 -->
            <div class="eSignThreeBox" v-if="eSignThreeBox">
                <div class="item">
                    <div class="name">真实姓名</div>
                    <div class="inp">
                        <input type="text" placeholder="请输入真实姓名" v-model="name" :disabled="inpDis">
                    </div>
                </div>
                <div class="item">
                    <div class="name">证件号码</div>
                    <div class="inp">
                        <input type="text" placeholder="请输入身份证号码" maxlength="18" v-model="idCard" :disabled="inpDis">
                    </div>
                </div>
                <div class="item">
                    <div class="name">联系电话</div>
                    <div class="inp">
                        <input type="text" maxlength="11" placeholder="请输入联系电话" readonly v-model="phone" :disabled="inpDis">
                    </div>
                </div>
                <div class="item">
                    <div class="name">验证码</div>
                    <div class="inp-code flex-bet">
                        <input type="text" placeholder="请输入验证码" maxlength="6" v-model="code">
                        <span v-show="codeShow" @click="threeKeySms">获取验证码</span>
                        <span v-show="!codeShow">{{timeDown}}s</span>
                    </div>
                </div>
                <div class="friend_tip">
                    温馨提示：手机号默认合同上联系电话，如需修改请联系业务员。不修改，可切换人脸识别认证。
                </div>
                <div class="submit" @click="submitAuth" style="margin-top:30px">
                    <zlButton text="提交" />
                </div>
                <div style="margin-top:10px" v-if="switchFlag" @click="handleSwitch">
                    <zlButton text="切换人脸识别" color="#4D62B8" background="#fff" />
                </div>
            </div>

            <!-- 老三要素认证 -->
            <div class="threeAuthBox" v-if="threeAuthBox">
                <div class="item">
                    <div class="name">真实姓名</div>
                    <div class="inp">
                        <input type="text" placeholder="请输入真实姓名" v-model="name">
                    </div>
                </div>
                <div class="item">
                    <div class="name">证件号码</div>
                    <div class="inp">
                        <input type="text" placeholder="请输入身份证号码" maxlength="18" v-model="idCard">
                    </div>
                </div>
                <div class="item">
                    <div class="name">联系电话</div>
                    <div class="inp">
                        <input type="text" maxlength="11" placeholder="请输入联系电话" readonly v-model="phone">
                    </div>
                </div>
                <div class="item">
                    <div class="name">验证码</div>
                    <div class="inp-code flex">
                        <input type="text" placeholder="请输入验证码" maxlength="6" v-model="code">
                        <span @click="getVerifyCode" v-show="codeShow">获取验证码</span>
                        <span v-show="!codeShow">{{timeDown}}s</span>
                    </div>
                </div>
                <div class="friend_tip">
                    温馨提示：手机号默认合同上联系电话，如需修改请联系业务员。不修改，可切换人脸识别认证。
                </div>
                <div class="submit" @click="submitAuthOld">
                    <zlButton text="提交" />
                </div>
                <div style="margin-top:10px" v-if="switchFlag" @click="handleSwitch">
                    <zlButton text="切换人脸识别" color="#4D62B8" background="#fff" />
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import zlButton from "@/components/zlButton.vue";
import { threeVerifyCode, threeSubmitAuth, eSignThreeKeySms, eSignThreeKeyCheck, postWxCode } from "@/api/user";
import { GetRequest } from "@/utils/index";
import { setAuthInfo, getAuthInfo } from "@/utils/auth"
import { getCheckPlatform } from "@/utils/cache"
export default {
    components: {
        zlButton
    },
    data() {
        return {
            title: "身份认证",
            backVisible: true,
            timeDown: 60,
            codeShow: true,
            phone: "",
            code: "",
            idCard: "",
            name: "",
            getCode: false,
            codeItemShow: false,
            threeAuthRes: true,
            threeKeyRes: null,
            paramFlags: {
                name: false,
                idCard: false,
                phone: false,
            },
            checkPlatform: "",
            errMessage: "",
            threeAuthBox: false,
            eSignThreeBox: false,
            switchFlag: false,
            btnFlag: true,
            timer: null,
            inpDis: false
        }
    },
    methods: {
        /////  e签包方法 ////////
        threeKeySms() {
            //判断
            if (!this.checkParams()) {
                return;
            }
            //请求接口
            let params = {
                idCard: this.idCard,
                name: this.name,
                phone: this.phone,
            }
            if (!this.btnFlag) {
                return;
            }
            this.btnFlag = false;
            //验证码倒计时
            if (!this.codeShow) {
                return;
            }
            eSignThreeKeySms(params).then(res => {
                this.btnFlag = true;
                console.log(res);
                let { status, data, statusMessage } = res;
                if (status) {
                    this.codeShow = false;
                    this.codeDownTimer();
                    this.inpDis = true;
                    if (!data.isPass) {
                        this.threeAuthRes = false;
                        this.threeKeyRes = data;
                        // this.getCode = true;
                    } else {
                        this.$store.dispatch('user/setAuthInfo').then(res => {
                            this.$router.replace({
                                path: "/authRes"
                            })
                        })
                    }
                } else {
                    this.codeShow = true;
                    if (statusMessage === "个人三要素信息不一致") {
                        statusMessage = "个人三要素信息不一致，请切换人脸识别进行认证"
                    }
                    this.$toast({
                        message: statusMessage
                    })

                }
            }).catch(err => {
                this.codeShow = true;
                this.btnFlag = true;
            })

        },
        resByCode() {
            console.log('12414141');
            let data = {
                code: this.code,
                recordKey: this.threeKeyRes.recordKey
            }
            if (this.code.length === 6) {
                eSignThreeKeyCheck(data).then(res => {
                    let { status, statusMessage } = res;
                    this.threeAuthRes = status;
                    this.errMessage = statusMessage;
                })
            }
        },
        submitAuth() {

            if (!this.checkParams()) {
                return;
            }
            if (!this.code) {
                this.$toast({
                    message: "验证码不能为空"
                })
                return;
            }
            if (this.code.length < 6) {
                this.$toast({
                    message: "请输入正确验证码"
                })
                return;
            }
            if (!this.threeKeyRes || !this.threeKeyRes.recordKey) {
                this.$toast({
                    message: "请获取验证码"
                })
                return;
            }
            let data = {
                code: this.code,
                recordKey: this.threeKeyRes.recordKey
            }
            //防止重复提交
            if (!this.btnFlag) {
                return;
            }
            this.btnFlag = false;
            eSignThreeKeyCheck(data).then(res => {
                this.btnFlag = true;
                let { status, statusMessage } = res;
                this.threeAuthRes = status;
                this.errMessage = statusMessage;
                if (this.threeAuthRes) {
                    this.$store.dispatch('user/setAuthInfo').then(res => {
                        this.$router.replace({
                            path: "/authRes"
                        })
                    })
                } else {
                    this.$router.replace({
                        path: "/authError",
                        query: {
                            authType: 1,
                            errMessage: this.errMessage
                        }
                    })
                }
            }).catch(err => {
                this.btnFlag = true;
            })


        },

        ////////  老三要素认证    ///////////
        getVerifyCode() {
            if (!this.checkParams()) {
                return;
            }
            let phone = this.phone;
            if (this.codeShow) {
                this.codeShow = false;
                threeVerifyCode({ phone }).then(res => {
                    console.log(res);
                    let { status, statusMessage } = res;
                    if (status) {
                        this.getCode = true;
                        this.codeDownTimer();
                    } else {
                        if (statusMessage === "个人三要素信息不一致") {
                            statusMessage = "个人三要素信息不一致，请切换人脸识别进行认证"
                        }
                        this.$toast({
                            message: statusMessage
                        })

                    }

                }).catch(err => {
                    this.codeShow = true;
                })

            }
        },
        //旧版提交认证
        submitAuthOld() {

            let data = {
                code: this.code,
                idCard: this.idCard,
                name: this.name,
                phone: this.phone,
            }
            if (!this.checkParams()) {
                return;
            }
            if (!this.getCode) {
                this.$toast({
                    message: "请获取验证码"
                })
                return;
            }
            if (!data.code) {
                this.$toast({
                    message: "验证码不能为空"
                })
                return;
            }
            //防止重复提交
            if (!this.btnFlag) {
                return;
            }
            this.btnFlag = false;
            threeSubmitAuth(data).then(res => {
                this.btnFlag = true;
                let { status, statusMessage, statusCode } = res;
                // let sMsg = !status ? '-1' : '1';
                if (status) {
                    this.$store.dispatch('user/setAuthInfo').then(res => {
                        this.$router.replace({
                            path: "/authRes"
                        })
                    })

                } else {
                    this.$router.replace({
                        path: "/authError",
                        query: {
                            authType: 1
                        }
                    })
                }

            }).catch(res => {
                this.btnFlag = true;
                this.$toast.clear();
                this.$toast({
                    message: "身份证信息有误,请重新提交"
                })
                this.getCode = false;
                this.code = "";
                this.codeShow = true;
                this.timeDown = 60;
            })
        },

        ///// 公用方法 //////
        handleSwitch() {
            this.$router.push({
                path: "/ocrIdAuth"
            })
            return;
        },
        checkParams() {
            // this.name = this.name.replace(/\s*/g,"");
            let flag = false;
            if (!this.name) {
                this.$toast({
                    message: "姓名不能为空"
                })
                return flag;
            }
            let idCard = this.idCard;
            if (!idCard) {
                this.$toast({
                    message: "身份证不能为空"
                })
                return flag;
            }
            if (idCard.length != 15 && idCard.length != 18) {
                this.$toast({
                    message: "身份证格式有误"
                })
                return flag;
            }
            let phone = this.phone;
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!reg.test(phone)) {
                this.$toast({
                    message: "手机号码格式有误"
                })
                return flag;
            }
            flag = true;
            return flag;
        },
        codeDownTimer() {
            this.timeDown = 60;
            this.timer = setInterval(() => {
                this.timeDown--;
                if (this.timeDown <= 0) {
                    this.codeShow = true;
                    this.timeDown = 60;
                    clearInterval(this.timer);
                }
            }, 1000);
        }
    },
    created() {
        this.phone = this.$store.state.user.loginPhone || "";
        let wxStatus = eval(this.$store.state.user.wxAuthed);
        if (!wxStatus) {
            let wxQuery = window.location.search;
            let wxCode = GetRequest(wxQuery);
            console.log('wxCode---', wxCode);
            if (wxCode && wxCode.code) {
                this.$store.dispatch("app/setLoading", false);
                postWxCode(wxCode).then(res => {
                    this.$store.dispatch("app/setLoading", true);
                    let { status } = res;
                    if (status) {
                        this.$store.dispatch("user/setWxAuthedAction", true);
                    }
                })
            }
        }

        //判断认证信息
        this.checkPlatform = getCheckPlatform();
        if (this.checkPlatform == "E_SIGN") {
            this.eSignThreeBox = true;
        } else {
            this.threeAuthBox = true;
        }
        console.log(this.checkPlatform);
        let authInfo = JSON.parse(getAuthInfo());
        if (!authInfo.checkPass) {
            this.switchFlag = true;
        }

    },

};
</script>

<style scoped>
div {
    box-sizing: border-box;
}
.three-auth {
    padding-top: 50px;
    height: 100vh;
    position: relative;
    background: #fff;
}
.three-auth .main {
    padding: 5px 20px 0 20px;
}
.three-auth .item {
    height: 94px;
    border-bottom: 1px solid #edf1f4;
    padding-top: 24px;
}
.three-auth .item .name {
    font-size: 14px;
    font-weight: 600;
}
.three-auth .item .inp {
    margin-top: 10px;
    color: #999999;
}
.three-auth .item .inp span {
    color: #116ed9;
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
}
.three-auth .item .inp input {
    width: 180px;
}
.flex-bet {
    display: flex;
    justify-content: space-between;
}
.three-auth .item .inp-code {
    margin-top: 10px;
    justify-content: space-between;
}
.three-auth .item .inp-code input {
    color: #999999;
}
.three-auth .item .inp-code span {
    color: #116ed9;
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
}
.three-auth .submit {
    margin-top: 60px;
}
.three-auth .submit button {
    width: 326px;
    height: 48px;
    border-radius: 24px;
    background: #0471fd;
    color: #fff;
}
.ta-btn {
    background: #0471fd;
}
.friend_tip {
    color: #f88070;
    font-size: 12px;
    margin-top: 6px;
}
</style>
